// src/pages/Menu.js
import React from 'react';
import { useState, useEffect} from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export default function Menu() {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const [userRoles, setUserRoles] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    if (isAuthenticated && user) {
      const roles = user['https://fynschool.com/roles'];
      setUserRoles(roles || []);
      setIsAdmin(roles && roles.includes('admin'));
    }
  }, [isAuthenticated, user]);

  return (
    <div>
      <nav>
        <ul>
          <li><a href="/welcome">Home</a></li>
          <li><a href="https:///learn.fynschool.com">Learn</a></li>
          {isAdmin && (<li><a href="/admin">Admin</a></li>)}
          <li>
            {isAuthenticated ? (
              <a href="#" onClick={() => logout({ returnTo: window.location.origin })}>
                Sign Out
              </a>
            ) : (
              <a href="#" onClick={() => loginWithRedirect()}>
                Sign In/Register
              </a>
            )}
          </li>
        </ul>

      </nav>


    </div>
  );
}
