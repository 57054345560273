import React, { useState, useEffect } from 'react';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';
import Menu from './Menu';

const Admin = () => {

  const [users, setUsers] = useState([]);




  return (
    <div>
      <Menu />

      <h2>Users:</h2>

    </div>
  );
};

export default Admin;
