import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
// import './App.css';
import LoginButton from './components/LoginButton';
import LogoutButton from './components/LogoutButton';
import Profile from './components/Profile';
import { useAuth0 } from '@auth0/auth0-react';
import Layout from './pages/Layout';
import Comprehensioncounty from './pages/Comprehensioncounty';
import Dictationdesert from './pages/Dictationdesert';
import Home from './pages/Home';
import Mathsmeadow from './pages/Mathsmeadow';
import Reasoningrainforest from './pages/Reasoningrainforest';
import Paperpyramid from './pages/Paperpyramid';
import Creativecarnival from './pages/Creativecarnival';
import Spellingsafari from './pages/Spellingsafari';
import Synonymsea from './pages/SynonymSea';
import Contact from './pages/Contact'
import Admin from './pages/Admin';
import Pricing from './pages/Pricing';
import ProfilePage from './pages/ProfilePage';
import Interviewisland from './pages/Interviewisland';
import Mathslevels from './pages/Mathslevels';
import Game from './pages/Game';
import Curriculum from './pages/Curriculum';

import Welcome from './pages/Welcome';
import Stats from './pages/Stats';
import Profiles from './pages/Profiles';

import GameEngine from './gameEngine/GameEngine';

// function RedirectToExternal() {
//   React.useEffect(() => {
//     window.location.href = 'https://learn.fynschool.com';
//   }, []);

//   return <p>Redirecting...</p>;
// }

export default function App() {
  const { isLoading, error } = useAuth0();

  return (
    <main className="App">
      {error && <p>Authentication Error</p>}
      {!error && isLoading && <p>Just a second... </p>}
      {!error && !isLoading && (
        <>
          <BrowserRouter>
            <Routes>
              {/* <Route path="/" element={<RedirectToExternal />} /> */}
                {/* <Route path="/home" element={<Home />} />
                <Route path="contact" element={<Contact />} />
                <Route path="pricing" element={<Pricing />} /> */}
                <Route path="/" element={<Navigate replace to="/welcome" />} />
                <Route path='welcome' element={<Welcome />} />
                <Route path='stats' element={<Stats />} />
                <Route path='profiles' element={<Profiles />} />
                <Route path="admin" element={<Admin />} />
                <Route path="/*" element={<Layout />}>
                <Route path="game" element={<Game />} />
                <Route path="comprehensioncounty" element={<Comprehensioncounty />} />
                <Route path='dictationdesert' element={<Dictationdesert/>}></Route>
                {/* <Route path="mathsmeadow" element={<Mathsmeadow />} /> */}
                <Route path="mathsmeadow" element={<Navigate replace to="/game" state={{ startScene: "MathsMeadowScene" }} />} />

                {/* <Route path="reasoningrainforest" element={<Reasoningrainforest />} /> */}
                <Route path="reasoningrainforest" element={<Navigate replace to="/game" state={{ startScene: "ReasoningRainforestScene" }} />} />
                <Route path="spellingsafari" element={<Spellingsafari />} />
                <Route path="paperpyramid" element={<Paperpyramid />} />
                {/* <Route path="creativecarnival" element={<Creativecarnival />} /> */}

                <Route path="creativecarnival" element={<Navigate replace to="/game" state={{ startScene: "CreativeCarnivalScene" }} />} />
                <Route path="interviewisland" element={<Interviewisland />} />
                <Route path="synonymsea" element={<Synonymsea />} />
                <Route path="profilepage" element={<ProfilePage />} />
                <Route path="mathslevels" element={<Mathslevels />} />
                <Route path="curriculum" element={<Curriculum />} />
              </Route>
            </Routes>
          </BrowserRouter>
          <Profile />
        </>
      )}
    </main>
  );
}
