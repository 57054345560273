import React, { useState, useEffect } from 'react';
import '../css/React.css';
import Menu from './Menu';
import { useAuth0 } from '@auth0/auth0-react';

function Welcome() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';
  localStorage.setItem('API_BASE_URL', API_BASE_URL);

  const { isAuthenticated, user } = useAuth0();
  const [localUser, setLocalUser] = useState(null);

  useEffect(() => {
    // Log authentication status
    // console.log(isAuthenticated ? 'Authenticated' : 'Not Authenticated');

    if (isAuthenticated && user) {
      // Set user in state and localStorage if authenticated
      setLocalUser(user.sub);
      localStorage.setItem('authenticated', 'true');
      localStorage.setItem('user', user.sub);
    } else {
      // Clear user and localStorage when not authenticated
      setLocalUser(null);
      localStorage.removeItem('authenticated');
      localStorage.removeItem('user');
    }
  }, [isAuthenticated, user]);



  const [version, setVersion] = useState('');

  useEffect(() => {
    fetch('/Version.txt')
      .then((response) => response.text())
      .then((data) => setVersion(data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="welcome-page">
      <Menu />
      <iframe
        id="godot-iframe"
        src="/godot/intro/index.html"
        style={{ width: '800px', height: '800px' }}
        title="Godot Game"
      ></iframe>
      <p className="version">{version}</p>
    </div>
  );
}


export default Welcome;
